var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "v-main",
        { staticClass: "pa-10" },
        [
          _c(
            "v-row",
            { staticClass: "mb-3" },
            [
              _c("v-col", { staticClass: "text-h5", attrs: { cols: "3" } }, [
                _vm._v(" Ducts File System Viewer "),
              ]),
            ],
            1
          ),
          _c(
            "v-expansion-panels",
            _vm._l(_vm.groupEntries, function (group) {
              return _c(
                "v-expansion-panel",
                { key: group[0] },
                [
                  _c(
                    "v-expansion-panel-header",
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "2" } }, [
                            _c("b", [
                              _vm._v(
                                _vm._s(group[1].metadata.group_key_text) +
                                  " (" +
                                  _vm._s(
                                    Object.keys(group[1].contents).length
                                  ) +
                                  ")"
                              ),
                            ]),
                          ]),
                          _c("v-col", { attrs: { cols: "3" } }, [
                            _c("span", { staticClass: "text-caption" }, [
                              _vm._v(_vm._s(group[1].metadata.group_key)),
                            ]),
                          ]),
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-3",
                                  attrs: { dense: "" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.openDialogMetadata(group[0])
                                    },
                                  },
                                },
                                [_vm._v("mdi-information-outline")]
                              ),
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-3",
                                  attrs: { dense: "" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.hoge()
                                    },
                                  },
                                },
                                [_vm._v("mdi-pencil")]
                              ),
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-3",
                                  attrs: { dense: "" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.hoge()
                                    },
                                  },
                                },
                                [_vm._v("mdi-delete")]
                              ),
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-3",
                                  attrs: { dense: "" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.hoge()
                                    },
                                  },
                                },
                                [_vm._v("mdi-download")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "v-expansion-panels",
                        _vm._l(
                          Object.entries(group[1].contents),
                          function (content) {
                            return _c(
                              "v-expansion-panel",
                              { key: group[0] + "::" + content[0] },
                              [
                                _c(
                                  "v-expansion-panel-header",
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c("v-col", { attrs: { cols: "2" } }, [
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(
                                                content[1].content_key_name
                                              )
                                            ),
                                          ]),
                                          _vm._v("     "),
                                          _c(
                                            "span",
                                            { staticClass: "text-caption" },
                                            [
                                              _vm._v(
                                                "(" +
                                                  _vm._s(
                                                    _vm.convertByteSize(
                                                      content[1].content_length
                                                    )
                                                  ) +
                                                  ")"
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("v-col", { attrs: { cols: "3" } }, [
                                          _c(
                                            "span",
                                            { staticClass: "text-caption" },
                                            [_vm._v(_vm._s(content[0]))]
                                          ),
                                        ]),
                                        _c(
                                          "v-col",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "mr-3",
                                                attrs: { dense: "" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.openDialogMetadata(
                                                      group[0],
                                                      content[0]
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "mdi-information-outline"
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "mr-3",
                                                attrs: { dense: "" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.hoge()
                                                  },
                                                },
                                              },
                                              [_vm._v("mdi-pencil")]
                                            ),
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "mr-3",
                                                attrs: { dense: "" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.hoge()
                                                  },
                                                },
                                              },
                                              [_vm._v("mdi-delete")]
                                            ),
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "mr-3",
                                                attrs: { dense: "" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.download(
                                                      group[0],
                                                      content[0],
                                                      content[1]
                                                        .content_key_name,
                                                      content[1].content_type
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("mdi-download")]
                                            ),
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "mr-3",
                                                attrs: { dense: "" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.hoge()
                                                  },
                                                },
                                              },
                                              [_vm._v("mdi-eye")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("v-expansion-panel-content"),
                              ],
                              1
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "600" },
              model: {
                value: _vm.dialogMetadata,
                callback: function ($$v) {
                  _vm.dialogMetadata = $$v
                },
                expression: "dialogMetadata",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [_vm._v(" Metadata Info ")]),
                  _c("v-simple-table", { attrs: { dense: "" } }, [
                    _c(
                      "tbody",
                      _vm._l(_vm.dialogMetadataContent, function (c) {
                        return _c("tr", { key: "dialog-metadata-" + c[0] }, [
                          _c("th", [_vm._v(_vm._s(c[0]))]),
                          ["created", "last_modified"].includes(c[0])
                            ? _c("td", [_vm._v(_vm._s(_vm.formatDate(c[1])))])
                            : c[0] == "content_length"
                            ? _c("td", [
                                _vm._v(_vm._s(_vm.convertByteSize(c[1]))),
                              ])
                            : _c("td", [_vm._v(_vm._s(c[1]))]),
                        ])
                      }),
                      0
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }